import React, { useEffect, useState } from "react";
import Tasks from "./../List/Tasks";
import NavBar from "./../Navbar/Navbar";
import useHttp from "./../../hooks/use-http";
import Map from "./../Map/Map";
import Counter from "./../Counter/Counter";
import { Box, Grid, GridItem } from "@chakra-ui/react";
import { getDefaultImage } from "../image/mapUtils";

function AdminPage() {
  const defaultValue = getDefaultImage();
  const [tasks, setTasks] = useState([]);
  const [chosenCoordinates, setChosenCoordinates] = useState({});
  const [chosenLocationData, setChosenLocationData] = useState({
    selectedCity: defaultValue.selectedCity,
    selectedFloor: defaultValue.selectedFloor,
  });
  const { isLoading, error, sendRequest: fetchTasks } = useHttp();
  const [isSelected, setIsSelected] = useState(false);
  const [triggerEffect, setTriggerEffect] = useState(false);
  const isAdmin = true;
  const addTask = (task) => {
    setTriggerEffect((prev) => !prev);
  };

  const deleteTaskHandler = (taskId) => {
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.id === taskId ? { ...task, isDeleted: true } : task
      )
    );
  };

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      tasksObj.forEach((element) => {
        loadedTasks.push({
          deskNumber: element.deskNumber,
          name: element.name,
          id: element.id,
          xCord: element.xCord,
          yCord: element.yCord,
          floor: element.floor,
          city: element.city,
          department: element.department,
        });
      });

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: `${process.env.REACT_APP_API_URL}Desk/allSpecific?floor=${chosenLocationData.selectedFloor}&city=${chosenLocationData.selectedCity}`,
        headers: {
          "Content-Type": "application/json",
        },
      },
      transformTasks
    );
  }, [fetchTasks, chosenLocationData, triggerEffect]);

  const chosenLocation = (data) => {
    setChosenLocationData(data);
  };
  const handleCoordinatesClick = (xCord, yCord) => {
    setIsSelected(true);
    const updatedChosenData = {
      ...chosenCoordinates,
      xCord: xCord,
      yCord: yCord,
      isSelected: isSelected,
    };
    console.log(updatedChosenData);
    setChosenCoordinates(updatedChosenData);
  };

  return (
    <div>
      <Grid templateColumns="repeat(6, 1fr)">
        <GridItem colSpan={6}>
          <NavBar chosenLocation={chosenLocation} />
        </GridItem>
        <GridItem colSpan={3} padding={4}>
          <Counter chosenLocation={chosenLocationData} tasks={tasks} />
          <Tasks
            items={tasks}
            loading={isLoading}
            error={error}
            onFetch={fetchTasks}
            onDelete={deleteTaskHandler}
            handleCoordinatesClick={handleCoordinatesClick}
            isAdmin={isAdmin}
          />
        </GridItem>

        <GridItem colSpan={3} padding={4}>
          <Box>
            <Map
              onAddDesk={addTask}
              chosenLocation={chosenLocationData}
              xCord={chosenCoordinates.xCord}
              yCord={chosenCoordinates.yCord}
              isSelected={isSelected}
              isAdmin={isAdmin}
            />
          </Box>
        </GridItem>
      </Grid>
    </div>
  );
}
export default AdminPage;
