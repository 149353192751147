import React, { useEffect, useState } from "react";
import useHttp from "../../hooks/use-http";
import Section from "../UI/Section";

import { Grid, GridItem } from "@chakra-ui/react";

const Counter = (props) => {
  const { isLoading, error, sendRequest: getNumberOfDesks } = useHttp();
  const [numbers, setNumbers] = useState({ all: null, empty: null });

  const allDesksAmount = props.tasks?.length;
  const emptyDesks = props.tasks.filter((task) => !task.name);
  const emptyDesksAmount = emptyDesks.length;
  const occupiedDesks = allDesksAmount - emptyDesksAmount;

  useEffect(() => {
    const transformTasks = (tasksObj) => {
      setNumbers({
        all: tasksObj[0],
        empty: tasksObj[1],
      });
    };

    const fetchNumber = async () => {
      try {
        const responseData = await getNumberOfDesks(
          {
            url: `${process.env.REACT_APP_API_URL}Desk/number?floor=${props.chosenLocation.selectedFloor}&city=${props.chosenLocation.selectedCity}`,
            headers: {
              "Content-Type": "application/json",
            },
          },
          transformTasks
        );
      } catch (error) {
        console.error("Failed to get number:", error);
      }
    };

    fetchNumber();
  }, [
    getNumberOfDesks,
    props.chosenLocation.selectedFloor,
    props.chosenLocation.selectedCity,
  ]);

  return (
    <Section>
      {isLoading && <p>Ładowanie...</p>}
      {error && <p>Wystąpił błąd</p>}
      {numbers.all !== null && numbers.empty !== null && (
        <Grid templateColumns="repeat(11, 1fr)" Center>
          <GridItem colStart={1} colEnd={4}>
            <Section>
              All places: <br />
              {allDesksAmount}
            </Section>
          </GridItem>
          <GridItem colStart={5} colEnd={8}>
            <Section>
              Empty places: <br />
              {emptyDesksAmount}
            </Section>
          </GridItem>
          <GridItem colStart={9} colEnd={12}>
            <Section>
              Seats occupied: <br />
              {occupiedDesks}
            </Section>
          </GridItem>
        </Grid>
      )}
    </Section>
  );
};

export default Counter;
