// W PersonPlace.js
import { Grid, GridItem } from "@chakra-ui/react";
import classes from "./Tasks.module.css";
export default function PersonPlace({
  deskNumber,
  name,
  floor,
  city,
  department,
  onClick,
  xCord,
  yCord,
  isSelected,
}) {
  return (
    <Grid
      templateColumns={`repeat(3, 1fr)`}
      padding={2}
      className={isSelected ? classes.Selected : ""}
    >
      <GridItem colSpan={1} onClick={() => onClick(xCord, yCord, city, floor)}>
        {deskNumber}
      </GridItem>
      <GridItem colSpan={1} onClick={() => onClick(xCord, yCord, city, floor)}>
        <div>{name}</div>
      </GridItem>
      <GridItem colSpan={1} onClick={() => onClick(xCord, yCord, city, floor)}>
        <div>{department}</div>
      </GridItem>
    </Grid>
  );
}
