import { useAuth0 } from "@auth0/auth0-react";
import { useState, useCallback } from "react";
import { useToast } from "@chakra-ui/react";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { getAccessTokenSilently } = useAuth0();
  const toast = useToast();

  const sendRequest = useCallback(
    async (requestConfig, applyData) => {
      setIsLoading(true);
      setError(null);
      const token = await getAccessTokenSilently();

      try {
        const response = await fetch(requestConfig.url, {
          method: requestConfig.method ? requestConfig.method : "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: requestConfig.body ? JSON.stringify(requestConfig.body) : null,
        });

        setIsLoading(false);
        if (response.status >= 400) {
          const errorResponse = await response.json();
          const error = errorResponse.errors[0];
          const errorMessage = `Message: ${error.message}`;
          throw new Error(errorMessage);
        } else {
          if (response.status !== 204) {
            const contentType = response.headers.get("content-type");
            if (contentType && contentType.includes("application/json")) {
              const data = await response.json();
              applyData(data);
            }
          }
        }
      } catch (error) {
        setError({ message: error.message || "Something went wrong!" });
        toast({
          title: "Error Occurred.",
          description: error.message || "Something went wrong!",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      } finally {
        setIsLoading(false);
      }
    },
    [getAccessTokenSilently, toast]
  );

  return {
    isLoading,
    error,
    sendRequest,
  };
};

export default useHttp;
