import React, { useEffect } from "react";
import { useState } from "react";
import NewPersonMarker from "./NewPersonMarker";
import Marker from "./Marker1";
import useHttp from "../../hooks/use-http";
import Section from "../UI/Section";
import { getDefaultImage, getImageForLocation } from "./../image/mapUtils";

const Map = (props) => {
  const defaultValue = getDefaultImage();
  const Image = getImageForLocation(
    defaultValue.selectedFloor,
    defaultValue.selectedCity
  );
  const [isNewPersonMarkerShown, setNewIsMarkerShown] = useState(false);
  const [isMarkerShown, setIsMarkerShown] = useState(false);
  const [markerPosition, setMarkerPosition] = useState({ x: 0, y: 0 });
  const [imagemap, setImagemap] = useState(Image);
  const { sendRequest: sendTaskRequest, isLoading, error } = useHttp();
  const { isAdmin } = props;

  const handleMapClick = (event) => {
    const { target: map } = event;
    const { left, top, width, height } = map.getBoundingClientRect();
    const x = event.clientX - left;
    const y = event.clientY - top;

    setNewIsMarkerShown(true);
    setIsMarkerShown(false);
    setMarkerPosition({ x: x / width, y: y / height });
  };
  useEffect(() => {
    const selectedImage = getImageForLocation(
      props.chosenLocation.selectedFloor,
      props.chosenLocation.selectedCity
    );
    setImagemap(selectedImage);
  }, [props.chosenLocation.selectedFloor, props.chosenLocation.selectedCity]);

  useEffect(() => {
    if (props.xCord !== markerPosition.x || props.yCord !== markerPosition.y) {
      setMarkerPosition({ x: props.xCord, y: props.yCord });
      setIsMarkerShown(props.isSelected);
      setNewIsMarkerShown(false);
    }
  }, [props.xCord, props.yCord]);

  const addPerson = async (taskText) => {
    try {
      const addedTask = {
        deskNumber: taskText.desknumber,
        xcord: taskText.x.toString(),
        ycord: taskText.y.toString(),
        floor: props.chosenLocation.selectedFloor,
        city: props.chosenLocation.selectedCity,
      };

      await sendTaskRequest({
        url: `${process.env.REACT_APP_API_URL}Desk`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          deskNumber: taskText.desknumber,
          xcord: taskText.x.toString(),
          ycord: taskText.y.toString(),
          floor: props.chosenLocation.selectedFloor,
          city: props.chosenLocation.selectedCity,
        },
      });
      setNewIsMarkerShown(false);
      props.onAddDesk(addedTask);
    } catch (error) {
      console.error("Failed to add person place:", error);
    }
  };

  const handleNewPersonData = (data) => {
    console.log("New data from NewPersonMarker:", data);
  };

  return (
    <Section isMapSection={true}>
      <div
        style={{
          position: "relative",
          alignSelf: "flex-start",
          width: "100%",
          height: "auto",
        }}
      >
        {isNewPersonMarkerShown && isAdmin && (
          <NewPersonMarker
            {...markerPosition}
            addPerson={addPerson}
            onNewPersonData={handleNewPersonData}
          />
        )}

        {isMarkerShown && <Marker {...markerPosition} />}

        <img
          src={imagemap}
          alt="floor"
          onClick={handleMapClick}
          style={{ width: "100%" }}
        />
      </div>
    </Section>
  );
};
export default Map;
