import React, { useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import AdminPage from "./components/Pages/AdminPage";
import AllList from "./components/Pages/AllList";
import context from "react-bootstrap/esm/AccordionContext";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const rolesClaim =
    "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/roles";
  const { user } = useAuth0();
  const userRoles = user[rolesClaim];
  const adminRole = "OfficeDeskMap: admin";

  const hasAdminRole = () => userRoles.includes(adminRole);

  const requireAdminRole = (element) => {
    return hasAdminRole() ? element : <Navigate to="/" />;
  };

  return (
    <Routes>
      <Route path="/Admin" element={requireAdminRole(<AdminPage />)} />
      <Route path="/" element={<AllList />} />
    </Routes>
  );
}

export default App;
