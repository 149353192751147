import image4 from "./../image/4pietro.png";
import image5 from "./../image/5pietro.png";
import image6 from "./../image/6pietro.png";
import image11 from "./../image/11pietro-W.png";
var tmp = {
  poznan: [{ 4: image4 }, { 5: image5 }, { 6: image6 }],
  warszawa: [{ 11: image11 }],
};

export const getImageForLocation = (selectedFloor, selectedCity) => {
  if (!selectedCity || !tmp[selectedCity]) {
    return null;
  }

  const cityMap = tmp[selectedCity];
  const floorObject = cityMap.find((obj) => obj[selectedFloor]);

  if (!floorObject) {
    return null;
  }

  return floorObject[selectedFloor];
};
export const getListOfFloor = (selectedCity) => {
  if (!selectedCity || !tmp[selectedCity]) {
    return [];
  }
  const cityMap = tmp[selectedCity];
  const listOfFloors = cityMap.map((floorObj) => Object.keys(floorObj)[0]);
  return listOfFloors;
};
export const getListOfCity = () => {
  return Object.keys(tmp);
};

export const getDefaultFloor = (selectedCity) => {
  if (selectedCity === "warszawa") {
    return Object.keys(tmp["warszawa"][0])[0];
  }
  return Object.keys(tmp["poznan"][0])[0];
};

export const getDefaultImage = () => {
  const defaultCity = "poznan";
  const defaultFloorKey = Object.keys(tmp["poznan"][1])[0];
  return { selectedCity: defaultCity, selectedFloor: defaultFloorKey };
};
