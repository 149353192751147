import React, { useState, useEffect } from "react";
import Section from "../UI/Section";
import classes from "./Navbar.module.css";
import { Grid, GridItem } from "@chakra-ui/react";
import {
  getListOfFloor,
  getListOfCity,
  getDefaultFloor,
  getDefaultImage,
} from "./../image/mapUtils";

const NavBar = (props) => {
  const [selectedFloor, setSelectedFloor] = useState("5");
  const [selectedCity, setSelectedCity] = useState("poznan");

  useEffect(() => {
    props.chosenLocation({
      selectedFloor,
      selectedCity,
    });
  }, []);

  const handleSelectionChange = (event, property) => {
    let newSelectedFloor = selectedFloor;
    let newSelectedCity = selectedCity;

    if (property === "floor") {
      newSelectedFloor = event.target.value;
    } else if (property === "city") {
      newSelectedCity = event.target.value.toLowerCase();
      newSelectedFloor = null;
    }

    setSelectedFloor(newSelectedFloor);
    setSelectedCity(newSelectedCity);

    props.chosenLocation({
      selectedFloor: newSelectedFloor
        ? newSelectedFloor
        : getDefaultFloor(newSelectedCity),
      selectedCity: newSelectedCity,
    });
  };

  return (
    <Section>
      <Grid templateColumns="repeat(6, 1fr)">
        <GridItem colStart={2} colEnd={4}>
          <label className={classes.label}>
            Select a floor:
            <select
              value={selectedFloor}
              onChange={(event) => handleSelectionChange(event, "floor")}
              className={classes.select}
            >
              {getListOfFloor(selectedCity).map((floor) => (
                <option key={floor} value={floor}>
                  {floor}
                </option>
              ))}
            </select>
          </label>
        </GridItem>
        <GridItem colStart={4} colEnd={6}>
          <label className={classes.label}>
            Select a city:
            <select
              value={selectedCity}
              onChange={(event) => handleSelectionChange(event, "city")}
              className={classes.select}
            >
              {getListOfCity().map((city) => (
                <option key={city} value={city}>
                  {city.charAt(0).toUpperCase() + city.slice(1)}
                </option>
              ))}
            </select>
          </label>
        </GridItem>
      </Grid>
    </Section>
  );
};

export default NavBar;
