import React, { useState } from "react";
import { Button, Grid, GridItem } from "@chakra-ui/react";
import classes from "./Tasks.module.css";

export default function AdminPersonPlace({
  deskNumber,
  name,
  floor,
  city,
  id,
  department,
  onSave,
  onDelete,
  onClick,
  xCord,
  yCord,
  isSelected,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(name ? name : null);
  const [departmentInputValue, setDepartmentInputValue] = useState(
    department ? department : null
  );

  return (
    <Grid
      templateColumns={`repeat(5, 1fr)`}
      padding={2}
      className={isSelected ? classes.Selected : ""}
    >
      <GridItem colSpan={1} onClick={() => onClick(xCord, yCord, city, floor)}>
        {deskNumber}
      </GridItem>
      <GridItem colSpan={1} onClick={() => onClick(xCord, yCord, city, floor)}>
        {isEditing ? (
          <input
            style={{ width: "80%", backgroundColor: "#D3D3D3" }}
            type="text"
            placeholder="Add new person"
            value={inputValue}
            onChange={(event) => setInputValue(event.target.value)}
          />
        ) : (
          <div>{inputValue}</div>
        )}
      </GridItem>
      <GridItem colSpan={1} onClick={() => onClick(xCord, yCord, city, floor)}>
        {isEditing ? (
          <input
            style={{ width: "80%", backgroundColor: "#D3D3D3" }}
            type="text"
            placeholder="Add department"
            value={departmentInputValue}
            onChange={(event) => setDepartmentInputValue(event.target.value)}
          />
        ) : (
          <div>{departmentInputValue}</div>
        )}
      </GridItem>
      <GridItem colSpan={2}>
        {!isEditing ? (
          <Button
            bg={"#999999"}
            margin={2}
            colorScheme="blackAlpha"
            onClick={() => setIsEditing(true)}
          >
            Update
          </Button>
        ) : (
          <Button
            colorScheme="blackAlpha"
            isDisabled={!inputValue || !departmentInputValue}
            onClick={() => {
              setIsEditing(false);
              onSave({
                deskNumber,
                name: inputValue,
                department: departmentInputValue,
                floor,
                city,
              });
            }}
          >
            Save
          </Button>
        )}
        <Button colorScheme="blackAlpha" onClick={() => onDelete(id)}>
          Delete
        </Button>
      </GridItem>
    </Grid>
  );
}
