import { Button } from "@chakra-ui/react";
import React, { useState } from "react";

const NewPersonMarker = (props) => {
  const { addPerson } = props;
  const [desknumber, setDeskNumber] = useState("");

  const handleButtonClick = () => {
    addPerson({
      id: Math.random(),
      desknumber,
      x: props.x,
      y: props.y,
    });

    props.onNewPersonData({
      id: Math.random(),
      desknumber,
      x: props.x,
      y: props.y,
    });
  };

  const handleInputChange = (event) => setDeskNumber(event.target.value);

  return (
    <div
      style={{
        width: "1.5%",
        aspectRatio: "1",
        borderRadius: "50%",
        background: "red",
        position: "absolute",
        left: `${props.x * 100}%`,
        top: `${props.y * 100}%`,
        transform: "translate(-50%, -50%)",
      }}
    >
      <div
        style={{
          display: "flex",
          position: "absolute",
          transform: "translateX(-50%)",
          top: "40px",
        }}
      >
        <input
          placeholder="Desk Number"
          value={desknumber}
          onChange={handleInputChange}
          style={{
            fontSize: "18px",
            border: "2px solid #000",
            padding: "10px 15px",
            width: "100px",
            marginRight: "10px",
          }}
        />
        <Button colorScheme="teal" onClick={handleButtonClick}>
          ✔️
        </Button>
      </div>
    </div>
  );
};

export default NewPersonMarker;
