import React from "react";
import "./animations.css";

const Marker = (props) => (
  <div
    style={{
      width: "1.5%",
      aspectRatio: "1",
      borderRadius: "50%",
      background: "red",
      position: "absolute",
      left: `${props.x * 100}%`,
      top: `${props.y * 100}%`,
      transform: "translate(-50%, -50%)",
      animation: "pointChange 2s linear infinite",
    }}
  />
);

export default Marker;
