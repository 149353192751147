import ReactDOM from "react-dom/client";

import App from "./App";
import { Auth0ProviderWithNavigate } from "./authorization/auth0-provider-with-navigate";
import { BrowserRouter } from "react-router-dom";
import { ProtectedLayout } from "./components/ProtectedLayout";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

const root = ReactDOM.createRoot(document.getElementById("root"));
const customTheme = extendTheme({
  styles: {
    global: {
      ".chakra-ui-light": {
        backgroundColor: "rgb(200,200,200)",
      },
    },
  },
});

root.render(
  <BrowserRouter>
    <ChakraProvider theme={customTheme}>
      <Auth0ProviderWithNavigate>
        <ProtectedLayout>
          <App />
        </ProtectedLayout>
      </Auth0ProviderWithNavigate>
    </ChakraProvider>
  </BrowserRouter>
);
