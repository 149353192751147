import classes from "./Section.module.css";

const Section = (props) => {
  const sectionClassName = props.isMapSection
    ? classes.mapSection
    : classes.section;

  return <section className={sectionClassName}>{props.children}</section>;
};

export default Section;
