// Tasks.js
import React, { useState } from "react";
import Section from "../UI/Section";
import classes from "./Tasks.module.css";
import useHttp from "../../hooks/use-http";
import PersonPlace from "./PersonPlace";
import AdminPersonPlace from "./AdminPersonPlace";
import { Grid, GridItem } from "@chakra-ui/react";

const Tasks = (props) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState(null);
  const { sendRequest: sendDeletedRow } = useHttp();
  const { sendRequest: sendUpdatedRow } = useHttp();
  const { isAdmin } = props;

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleRowClick = (xCord, yCord, city, floor) => {
    props.handleCoordinatesClick(xCord, yCord);
    if (isAdmin === false) {
      props.handleCityAndFloorClick(city, floor);
    }
    setSelectedRow({ xCord, yCord, city, floor });
  };

  const handleSave = (row) => {
    try {
      sendUpdatedRow({
        url: `${process.env.REACT_APP_API_URL}Desk/assign`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: {
          deskNumber: row.deskNumber,
          department: row.department ? row.department : null,
          floor: row.floor,
          city: row.city,
          name: row.name ? row.name : null,
        },
      });
    } catch (error) {
      console.error("Failed to update", error);
    }
  };

  const handleDelete = (id) => {
    console.log("Delete clicked for row:", id);
    try {
      sendDeletedRow({
        url: `${process.env.REACT_APP_API_URL}Desk/${id}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });
      props.onDelete(id);
    } catch (error) {
      console.error("Failed to delete row", error);
    }
  };

  const filteredItems = props.items.filter((item) => {
    const deskNumber = item.deskNumber || "";
    const name = item.name || "";
    const department = item.department || "";
    return (
      deskNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
      name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      department.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const sortedData = React.useMemo(() => {
    return [...filteredItems].sort((a, b) => {
      const deskNumberA = a.deskNumber || "";
      const deskNumberB = b.deskNumber || "";
      return deskNumberA.localeCompare(deskNumberB, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  }, [filteredItems]);

  const data = React.useMemo(() => sortedData, [sortedData]);

  const isSelectedRow = (item) => {
    return (
      selectedRow &&
      selectedRow.xCord === item.xCord &&
      selectedRow.yCord === item.yCord &&
      selectedRow.city === item.city &&
      selectedRow.floor === item.floor
    );
  };
  return (
    <Section>
      <div className={classes.SearchBox1}>
        <input
          type="text"
          id="search"
          placeholder="Search..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <table>
        <thead>
          <Grid
            bg={"rgb(210, 0, 20)"}
            textColor={"white"}
            templateColumns={`repeat(${isAdmin ? 5 : 3}, 1fr)`}
            padding={2}
          >
            <GridItem colSpan={1}>
              <strong>Desk Number</strong>
            </GridItem>
            <GridItem colSpan={1}>
              <strong>Name</strong>
            </GridItem>
            <GridItem colSpan={1}>
              <strong>Department</strong>
            </GridItem>
          </Grid>
        </thead>
        <tbody>
          {data.map((item) =>
            item.isDeleted ? null : isAdmin ? (
              <AdminPersonPlace
                key={item.deskNumber + item.name}
                deskNumber={item.deskNumber}
                name={item.name}
                department={item.department}
                id={item.id}
                floor={item.floor}
                city={item.city}
                onSave={handleSave}
                onDelete={handleDelete}
                xCord={item.xCord}
                yCord={item.yCord}
                isSelected={isSelectedRow(item)}
                onClick={handleRowClick}
              />
            ) : (
              <PersonPlace
                key={item.deskNumber + item.name}
                deskNumber={item.deskNumber}
                name={item.name}
                department={item.department}
                id={item.id}
                floor={item.floor}
                city={item.city}
                xCord={item.xCord}
                yCord={item.yCord}
                isSelected={isSelectedRow(item)}
                onClick={handleRowClick}
              />
            )
          )}
        </tbody>
      </table>
    </Section>
  );
};

export default Tasks;
