import React, { useEffect, useState } from "react";
import useHttp from "../../hooks/use-http";
import Tasks from "../List/Tasks";
import { Grid, GridItem } from "@chakra-ui/react";
import Map from "../Map/Map";
import { getDefaultImage } from "../image/mapUtils";

function AllList() {
  const defaultValue = getDefaultImage();
  const { isLoading, error, sendRequest: fetchTasks } = useHttp();
  const [tasks, setTasks] = useState([]);
  const [chosenCoordinates, setChosenCoordinates] = useState({});
  const [choosenCityAndFloor, setchoosenCityAndFloor] = useState({
    selectedCity: defaultValue.selectedCity,
    selectedFloor: defaultValue.selectedFloor,
  });
  const isAdmin = false;
  const [isSelected, setIsSelected] = useState(false);

  const handleCityAndFloorClick = (city, floor) => {
    const updatedChosenCityAndFloor = {
      ...choosenCityAndFloor,
      selectedCity: city,
      selectedFloor: floor,
    };
    setchoosenCityAndFloor(updatedChosenCityAndFloor);
  };

  const handleCoordinatesClick = (xCord, yCord) => {
    setIsSelected(true);
    const updatedChosenData = {
      ...chosenCoordinates,
      xCord: xCord,
      yCord: yCord,
      isSelected: isSelected,
    };
    console.log(updatedChosenData);
    setChosenCoordinates(updatedChosenData);
  };
  useEffect(() => {
    const transformTasks = (tasksObj) => {
      const loadedTasks = [];

      tasksObj.forEach((element) => {
        loadedTasks.push({
          deskNumber: element.deskNumber,
          name: element.name,
          id: element.id,
          xCord: element.xCord,
          yCord: element.yCord,
          floor: element.floor,
          city: element.city,
          department: element.department,
        });
      });

      setTasks(loadedTasks);
    };

    fetchTasks(
      {
        url: `${process.env.REACT_APP_API_URL}Desk/all`,
        headers: {
          "Content-Type": "application/json",
        },
      },
      transformTasks
    );
  }, [fetchTasks]);
  return (
    <Grid templateColumns="repeat(14, 1fr)">
      <GridItem colStart={1} colEnd={7} padding={4}>
        <Tasks
          items={tasks}
          loading={isLoading}
          error={error}
          onFetch={fetchTasks}
          handleCoordinatesClick={handleCoordinatesClick}
          handleCityAndFloorClick={handleCityAndFloorClick}
          isAdmin={isAdmin}
        />
      </GridItem>
      <GridItem colStart={8} colEnd={15} paddingTop={4} paddingRight={4}>
        <Map
          chosenLocation={choosenCityAndFloor}
          xCord={chosenCoordinates.xCord}
          yCord={chosenCoordinates.yCord}
          isSelected={isSelected}
          isAdmin={isAdmin}
        />
      </GridItem>
    </Grid>
  );
}

export default AllList;
