import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Box } from "@chakra-ui/react";

export const ProtectedLayout = ({ children }) => {
  var Wrapper = () => (
    <Box p="4" rounded={"lg"} boxShadow={"lg"}>
      {children}
    </Box>
  );

  const Component = withAuthenticationRequired(Wrapper, {});
  return <Component />;
};
